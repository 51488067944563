exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-code-of-conduct-index-js": () => import("./../../../src/pages/code-of-conduct/index.js" /* webpackChunkName: "component---src-pages-code-of-conduct-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-doggo-js": () => import("./../../../src/pages/doggo.js" /* webpackChunkName: "component---src-pages-doggo-js" */),
  "component---src-pages-how-we-work-interns-index-js": () => import("./../../../src/pages/how-we-work/interns/index.js" /* webpackChunkName: "component---src-pages-how-we-work-interns-index-js" */),
  "component---src-pages-how-we-work-learners-index-js": () => import("./../../../src/pages/how-we-work/learners/index.js" /* webpackChunkName: "component---src-pages-how-we-work-learners-index-js" */),
  "component---src-pages-how-we-work-partners-index-js": () => import("./../../../src/pages/how-we-work/partners/index.js" /* webpackChunkName: "component---src-pages-how-we-work-partners-index-js" */),
  "component---src-pages-impact-index-js": () => import("./../../../src/pages/impact/index.js" /* webpackChunkName: "component---src-pages-impact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-internships-index-js": () => import("./../../../src/pages/internships/index.js" /* webpackChunkName: "component---src-pages-internships-index-js" */),
  "component---src-pages-jobs-board-index-js": () => import("./../../../src/pages/jobs-board/index.js" /* webpackChunkName: "component---src-pages-jobs-board-index-js" */),
  "component---src-pages-journal-index-js": () => import("./../../../src/pages/journal/index.js" /* webpackChunkName: "component---src-pages-journal-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-school-of-code-index-js": () => import("./../../../src/pages/school-of-code/index.js" /* webpackChunkName: "component---src-pages-school-of-code-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-virtual-summit-competition-index-js": () => import("./../../../src/pages/virtual-summit-competition/index.js" /* webpackChunkName: "component---src-pages-virtual-summit-competition-index-js" */),
  "component---src-pages-virtual-summit-index-js": () => import("./../../../src/pages/virtual-summit/index.js" /* webpackChunkName: "component---src-pages-virtual-summit-index-js" */),
  "component---src-pages-virtual-summit-terms-and-conditions-index-js": () => import("./../../../src/pages/virtual-summit-terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-virtual-summit-terms-and-conditions-index-js" */),
  "component---src-pages-whats-on-index-js": () => import("./../../../src/pages/whats-on/index.js" /* webpackChunkName: "component---src-pages-whats-on-index-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */)
}

